import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import {Link} from 'react-router-dom';

import {
  CustomTable,
  FormikDateInput,
  FormikInput,
  ProgressIndicator,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {interestWaiverApi} from '../api';
import * as messages from '../messages';
import {InterestWaiverSearchResponse} from '../types/interestWaiver';
import {InterestWaiverSearchRequest} from '../types/request/interestWaiver';
import {formatDate} from '../util';
import {InterestWaiverCreateModal} from '../components/interestWaivers';
import {interestWaiverSearchSchema} from '../schema/interestWaiver';

const InterestWaivers = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [interestWaivers, setInterestWaivers] = useState<InterestWaiverSearchResponse[]>([]);
  const {showErrorAlert} = useAlerts();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {permissions} = useUserContext();

  const handleSearch = useCallback(async (interestWaiverSearchRequest: InterestWaiverSearchRequest) => {
    try {
      setIsLoading(true);
      const interestWaivers = await interestWaiverApi.search(interestWaiverSearchRequest);
      setInterestWaivers(interestWaivers);
      setHasSearched(true);
      setIsLoading(false);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setIsLoading(false);
    }
  }, [
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Interest Waiver ID', className: 'text-center', sortKey: 'fileNumber'},
      {title: 'Parcel ID', className: 'text-center', sortKey: 'parcel.parcelNumber'},
      {title: 'Parcel Address', className: 'text-center', sortKey: 'parcel.address.fullDisplay'},
      {title: 'Owner Names', className: 'text-center', sortKey: 'owner.ownerNames'},
      {title: 'Date Received', className: 'text-center', sortKey: 'receivedOn'}
    ],
    renderRow: (interestWaiver: InterestWaiverSearchResponse) => {
      return <tr key={interestWaiver.id}>
        <td className="text-center text-nowrap">
          <Link to={`/interest-waivers/${interestWaiver.id}`}>
            {interestWaiver.fileNumber}
          </Link>
        </td>
        <td className="text-center text-nowrap">{interestWaiver.parcel.parcelNumber}</td>
        <td className="text-center">{interestWaiver.parcel.address.fullDisplay}</td>
        <td className="text-center">{interestWaiver.owner.ownerNames}</td>
        <td className="text-center text-nowrap">{formatDate(interestWaiver.receivedOn)}</td>
      </tr>;
    },
    initialSort: {direction: 'desc' as const, sortKey: 'year'},
    items: interestWaivers,
    noResultsMessage: messages.NO_INTEREST_WAIVERS_FOUND,
    paginatorConfig: {
      perPage: 100
    }
  }), [
    interestWaivers
  ]);

  const handleReset = useCallback((resetForm: () => void) => {
    setInterestWaivers([]);
    setHasSearched(false);
    resetForm();
  }, []);

  const initialValues: InterestWaiverSearchRequest = useMemo(() => ({
    fileNumber: '',
    parcelAddress: '',
    parcelNumber: '',
    receivedOn: undefined,
    ownerNames: ''
  }), []);

  return <>
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSearch}
            validationSchema={interestWaiverSearchSchema}>
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Container fluid>
            {permissions.hasWriteAccess && <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <Button onClick={() => setModalIsOpen(true)}
                        color="primary">
                  Create Interest Waiver
                </Button>
              </Col>
            </Row>}
            <Card className="mb-4">
              <CardHeader>Search Interest Waivers</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="2">
                    <FormikInput name="fileNumber"
                                 labelText="Interest Waiver ID"/>
                  </Col>
                  <Col sm="2">
                    <FormikInput name="parcelNumber"
                                 labelText="Parcel ID"/>
                  </Col>
                  <Col sm="3">
                    <FormikInput name="parcelAddress"
                                 labelText="Parcel Address"/>
                  </Col>
                  <Col sm="3">
                    <FormikInput name="ownerNames"
                                 labelText="Owner Names"/>
                  </Col>
                  <Col sm="2">
                    <FormikDateInput name="receivedOn"
                                     formGroupClass="mb-0"
                                     labelText="Date Received"/>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button color="success"
                            size="sm"
                            type="submit"
                            disabled={formikProps.isSubmitting}
                            onClick={formikProps.submitForm}
                            className="mr-2">
                      Search
                    </Button>
                    <Button color="danger"
                            onClick={() => handleReset(formikProps.resetForm)}
                            size="sm">
                      Reset
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {isLoading && <ProgressIndicator className="mb-4"/>}
            {hasSearched && <Card>
              <CardHeader>
                Search Results
              </CardHeader>
              <CustomTable {...tableProps}/>
            </Card>}
          </Container>
        </Form>)}
    </Formik>
    <InterestWaiverCreateModal isOpen={modalIsOpen}
                               toggleOpen={() => setModalIsOpen(!modalIsOpen)}/>
  </>;
};

export default InterestWaivers;