import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikCheckboxGroup, FormikDateInput, FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {formatBoolean, formatDate} from '../../util';
import {MttHearing} from '../../types/appeal';
import {mttHearingSchema} from '../../schema/appeal';
import {CollectionContext} from '../../contexts';

type  Props = {
  mttHearing: MttHearing
  onSave: (mttHearing: MttHearing) => void
}

const AppealMttHearingCard = ({
                                mttHearing,
                                onSave
                              }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (mttHearing: MttHearing) => {
    await onSave(mttHearing);
    setModalIsOpen(false);
  }, [
    onSave
  ]);

  const initialValues = useMemo(() => ({
    ...mttHearing
  }), [
    mttHearing
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            MTT Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th style={{width: '13%'}}>Docket Number</th>
            <th style={{width: '12%'}}>Received Date</th>
            <th className="w-15">Analyst Assigned</th>
            <th className="w-15">Attorney Name</th>
            <th className="w-15">Firm Name</th>
            <th className="w-15">Address</th>
            <th className="w-15">Response Due</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{mttHearing.docketNumber}&nbsp;</td>
            <td>{formatDate(mttHearing.receivedOn)}&nbsp;</td>
            <td>{mttHearing.analystName}&nbsp;</td>
            <td>{mttHearing.attorneyName}&nbsp;</td>
            <td>{mttHearing.legalFirmName}&nbsp;</td>
            <td>{mttHearing.legalFirmAddress}&nbsp;</td>
            <td>{formatDate(mttHearing.responseDueOn)}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th style={{width: '13%'}}>Treasury Response Date</th>
            <th style={{width: '12%'}}>Response Days</th>
            <th className="w-15">Legal Issue</th>
            <th className="w-15">Referee Name</th>
            <th className="w-15">MTT Hearing Date</th>
            <th className="w-15">Proposed Judgement Date</th>
            <th className="w-15">Proposed Judgement</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(mttHearing.treasuryResponseOn)}&nbsp;</td>
            <td>{mttHearing.responseDays}</td>
            <td>{mttHearing.issue}&nbsp;</td>
            <td>{mttHearing.refereeName}&nbsp;</td>
            <td>{formatDate(mttHearing.hearingOn)}&nbsp;</td>
            <td>{formatDate(mttHearing.proposedJudgementOn)}&nbsp;</td>
            <td>{mttHearing.proposedJudgement}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th style={{width: '13%'}}>Treasury Exception Filed</th>
            <th style={{width: '12%'}}>Treasury Exception Date</th>
            <th className="w-15">Days to Exception Response</th>
            <th className="w-30">Final Opinion Date</th>
            <th className="w-30">Final Opinion</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatBoolean(mttHearing.treasuryExceptionFiled)}&nbsp;</td>
            <td>{formatDate(mttHearing.treasuryExceptionOn)}&nbsp;</td>
            <td>{mttHearing.daysToExceptionResponse}</td>
            <td>{formatDate(mttHearing.finalOpinionOn)}&nbsp;</td>
            <td>{mttHearing.finalOpinion}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th style={{width: '13%'}}>Final Opinion Judge Name</th>
            <th style={{width: '27%'}}>Petitioner Motion for Reconsideration Filed?</th>
            <th className="w-30">Treasury Motion for Reconsideration Filed?</th>
            <th className="w-30">Motion for Reconsideration Final Decision</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{mttHearing.finalOpinionJudgeName}&nbsp;</td>
            <td>{formatBoolean(mttHearing.petitionerMotion)}&nbsp;</td>
            <td>{formatBoolean(mttHearing.treasuryMotion)}&nbsp;</td>
            <td>{mttHearing.reconsiderationDecision}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit MTT Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={mttHearingSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput autoFocus
                             name="docketNumber"
                             labelText="Docket Number"/>
              </Col>
              <Col sm="4">
                <FormikDateInput name="receivedOn"
                                 formGroupClass="mb-0"
                                 labelText="Received Date"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="analystId" labelText="Analyst Assigned">
                  <option value="">Select</option>
                  {collections?.analysts.map(analyst =>
                    <option key={analyst.id} value={analyst.id}>{analyst.fullName}</option>)}
                </FormikSelect>
              </Col>
            </Row>
            <Row className="row-border-top-3 mt-0">
              <Col sm="3">
                <FormikInput name="attorneyName"
                             labelText="Attorney Name"/>
              </Col>
              <Col sm="3">
                <FormikInput name="legalFirmName"
                             labelText="Firm Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="legalFirmAddress"
                             labelText="Address"/>
              </Col>
              <Col sm="2">
                <FormikDateInput name="responseDueOn"
                                 formGroupClass="mb-0"
                                 labelText="Response Due"/>
              </Col>
            </Row>
            <Row className="row-border-top-3 mt-0">
              <Col sm="3">
                <FormikDateInput name="treasuryResponseOn"
                                 formGroupClass="mb-0"
                                 labelText="Treasury Response Date"/>
              </Col>
              <Col sm="3">
                <FormikInput name="issue"
                             labelText="Legal Issue"/>
              </Col>
              <Col sm="3">
                <FormikSelect name="refereeName"
                              labelText="Referee">
                  <option value="">Select</option>
                  {collections?.referees.map(referee =>
                    <option key={referee} value={referee}>{referee}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="3">
                <FormikDateInput name="hearingOn"
                                 formGroupClass="mb-0"
                                 labelText="MTT Hearing Date"/>
              </Col>
            </Row>
            <Row className="row-border-top-3 mt-0">
              <Col sm="6">
                <FormikDateInput name="proposedJudgementOn"
                                 formGroupClass="mb-0"
                                 labelText="Proposed Judgement Date"/>
              </Col>
              <Col sm="6">
                <FormikSelect name="proposedJudgement"
                              labelText="Proposed Judgement">
                  <option value="">Select</option>
                  {collections?.mttHearingDecisions.map(mttHearingDecision =>
                    <option key={mttHearingDecision} value={mttHearingDecision.toUpperCase()}>{mttHearingDecision}</option>)}
                </FormikSelect>
              </Col>
            </Row>
            <Row className="row-border-top-3 mt-0">
              <Col sm="3">
                <FormikCheckboxGroup checkboxes={[
                  {name: 'treasuryExceptionFiled', labelText: 'Treasury Exception Filed?'}
                ]}/>
              </Col>
            </Row>
            <Row>
              <Col sm="3">
                <FormikDateInput name="treasuryExceptionOn"
                                 formGroupClass="mb-0"
                                 labelText="Treasury Exception Date"/>
              </Col>
              <Col sm="3">
                <FormikDateInput name="finalOpinionOn"
                                 formGroupClass="mb-0"
                                 labelText="Final Opinion Date"/>
              </Col>
              <Col sm="3">
                <FormikSelect name="finalOpinion" labelText="Final Opinion">
                  <option value="">Select</option>
                  {collections?.mttHearingDecisions.map(mttHearingDecision =>
                    <option key={mttHearingDecision} value={mttHearingDecision.toUpperCase()}>{mttHearingDecision}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="3">
                <FormikInput name="finalOpinionJudgeName"
                             labelText="Final Opinion Judge Name"/>
              </Col>
            </Row>
            <Row className="row-border-top-3 mt-0">
              <Col>
                <FormikCheckboxGroup
                  inline
                  checkboxes={[
                    {name: 'petitionerMotion', labelText: 'Petitioner Motion for Reconsideration Filed?'},
                    {name: 'treasuryMotion', labelText: 'Treasury Motion for Reconsideration Filed?'}
                  ]}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="reconsiderationDecision"
                             labelText="Motion for Reconsideration Final Decision"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealMttHearingCard;