import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDateInput, FormikNumberInput, useUserContext} from '@reasoncorp/kyber-js';

import {InterestWaiverResponse} from '../../types/interestWaiver';
import {InterestWaiverInformationRequest, InterestWaiverRequest} from '../../types/request/interestWaiver';
import {formatDate} from '../../util';
import {interestWaiverFileInformationSchema} from '../../schema/interestWaiver';

type Props = {
  interestWaiver: InterestWaiverResponse
  onSave: (interestWaiverRequest: InterestWaiverRequest) => void
}

const InterestWaiverFileInformationCard = ({
                                             interestWaiver,
                                             onSave
                                           }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (interestWaiverInformationRequest: InterestWaiverInformationRequest) => {
    const interestWaiverRequest = {
      ...interestWaiver,
      ...interestWaiverInformationRequest
    } as InterestWaiverRequest;

    await onSave(interestWaiverRequest);
    setModalIsOpen(false);
  }, [
    interestWaiver,
    onSave
  ]);

  const initialValues: InterestWaiverInformationRequest = useMemo(() => ({
    receivedOn: interestWaiver.receivedOn,
    numberOfYearsRequested: interestWaiver.numberOfYearsRequested
  }), [
    interestWaiver
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            File Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-50">Date Received</th>
            <th className="w-50">Number of Requested Years</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(interestWaiver.receivedOn)}</td>
            <td>{interestWaiver.numberOfYearsRequested}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="md">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit File Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={interestWaiverFileInformationSchema}>
        {(formikProps) => (<>
          <CardBody>
            <Row>
              <Col sm="6">
                <FormikDateInput name="receivedOn"
                                 formGroupClass="mb-0"
                                 labelText="Date Received"/>
              </Col>
              <Col sm="6">
                <FormikNumberInput name="numberOfYearsRequested"
                                   formGroupClass="mb-0"
                                   labelText="Number of Requested Years"/>
              </Col>
            </Row>
          </CardBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default InterestWaiverFileInformationCard;