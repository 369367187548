import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';

import {CustomTable, ProgressIndicator, SelectTableCell, SelectTableCellData, useAlerts} from '@reasoncorp/kyber-js';

import {BonaFideGenerateVoucherLettersModal, BonaFideNavBar} from '../../components/bonaFides';
import {BonaFideSearchResponse} from '../../types/bonaFide';
import {bonaFideApi, bonaFideLetterApi} from '../../api';
import *  as messages from '../../messages';

type BonaFide = BonaFideSearchResponse & {
  selected: boolean
};

const BonaFideLetters = () => {
  const [bonaFides, setBonaFides] = useState<BonaFide[]>([]);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    loading: false,
    loadError: false,
    processing: false
  });

  const selectedBonaFideIds = useMemo(() => {
    return bonaFides.filter(bonaFide => bonaFide.selected).map(bonaFide => bonaFide.id);
  }, [
    bonaFides
  ]);

  const handleColumnSelect = useCallback((data: SelectTableCellData) => {
    setBonaFides(bonaFides.map(bonaFide =>
      data.itemIds.includes(bonaFide.id) ? ({...bonaFide, selected: !data.prevSelection}) : bonaFide));
  }, [
    bonaFides
  ]);

  const handleCellSelect = useCallback((data: SelectTableCellData) => {
    setBonaFides(
      bonaFides.map(bonaFide => bonaFide.id === data.itemId ? {...bonaFide, selected: !data.prevSelection} : bonaFide)
    );
  }, [
    bonaFides
  ]);

  useEffect(() => {
    const loadBonaFides = async () => {
      try {
        setLoadingState({loading: true, loadError: false, processing: false});
        const bonaFideSearchResponses = await bonaFideApi.search({hasLetter: false, hasBilledYears: true});
        const bonaFides = bonaFideSearchResponses.map(bonaFideSearchResponses => ({
          ...bonaFideSearchResponses,
          selected: false
        }));
        setBonaFides(bonaFides);
        setLoadingState({loading: false, loadError: false, processing: false});
        setIsInitialLoad(false);
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadBonaFides();
  }, [
    showErrorAlert
  ]);

  const handleCreate = useCallback(async (letterSentOn: Date | string) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await bonaFideLetterApi.createBatch({
        bonaFideIds: selectedBonaFideIds,
        letterSentOn
      });
      const bonaFides = await bonaFideApi.search({hasLetter: false, hasBilledYears: true});
      setBonaFides(bonaFides as BonaFide[]);
      showSuccessAlert(messages.LETTER_BATCH_CREATE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_CREATE_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }

    setIsModalOpen(false);
  }, [
    showErrorAlert,
    showSuccessAlert,
    selectedBonaFideIds
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', onChange: handleColumnSelect, className: 'w-5'},
      {title: 'Bona Fide ID', className: 'text-left w-15', sortKey: 'fileNumber'},
      {title: 'Parcel ID', className: 'text-center w-20', sortKey: 'parcel.parcelNumber'},
      {title: 'Parcel Address', className: 'text-center w-30', sortKey: 'parcel.address.fullDisplay'},
      {title: 'Owner Names', className: 'text-center w-30', sortKey: 'owner.ownerNames'}
    ],
    initialSort: {
      sortKey: 'fileNumber',
      direction: 'asc' as const
    },
    paginatorConfig: {
      perPage: 100
    },
    renderRow: (bonaFide: BonaFide) => {
      return <tr key={bonaFide.id}>
        <SelectTableCell itemId={bonaFide.id}
                         ariaLabel={`Toggle select for letter for Bona Fide # ${bonaFide.fileNumber}`}
                         selected={bonaFide.selected}
                         onChange={handleCellSelect}/>
        <td className="text-left w-15">
          {bonaFide.fileNumber}
        </td>
        <td className="text-center w-20">
          {bonaFide.parcel.parcelNumber}
        </td>
        <td className="text-center w-30">
          {bonaFide.parcel.address.fullDisplay}
        </td>
        <td className="text-center w-30">
          {bonaFide.owner.ownerNames}
        </td>
      </tr>;
    },
    items: bonaFides,
    noResultsMessage: messages.NO_BONA_FIDES_FOUND
  }), [
    bonaFides,
    handleColumnSelect,
    handleCellSelect
  ]);

  return <Container fluid>
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <BonaFideNavBar/>
      </Col>
    </Row>
    {loadingState.loading && isInitialLoad && <ProgressIndicator/>}
    {!loadingState.loadError && !isInitialLoad && <>
      <Row className="mb-4">
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col sm="6">Generate Voucher Letters</Col>
                <Col className="d-flex justify-content-end" sm="6">Viewing {bonaFides.length} Bona Fides</Col>
              </Row>
            </CardHeader>
            <Row>
              <Col>
                {loadingState.loading && <ProgressIndicator/>}
                {!loadingState.loading && <CustomTable {...tableProps}/>}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button color="success"
                  disabled={loadingState.processing || selectedBonaFideIds.length === 0}
                  onClick={() => setIsModalOpen(true)}>
            Generate Letter(s)
          </Button>
        </Col>
      </Row>
      <BonaFideGenerateVoucherLettersModal isOpen={isModalOpen}
                                           onSubmit={handleCreate}
                                           onCancel={() => setIsModalOpen(false)}/>
    </>}
  </Container>;
};

export default BonaFideLetters;