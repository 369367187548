import * as Yup from 'yup';
import {isAfter, isEqual} from 'date-fns';

import * as messages from '../../messages';
import {getSummerLastDueDate, getWinterLastDueDate} from '../../util/bonaFideCalculationUtils';
import {BonaFideDenialYear} from '../../types/bonaFide';
import {formatDate} from '../../util';

const validateBilledOn = function (this: any, values: any) {
  const {year, taxLevySeason, billedOn} = values as BonaFideDenialYear;
  if (!year || !taxLevySeason) {
    return true;
  }

  const minDate = taxLevySeason === 'WINTER' ?
    getWinterLastDueDate(Number(year)) :
    getSummerLastDueDate(Number(year));

  const isOnOrAfterPaymentDueDate = minDate !== null &&
    billedOn !== null && (
      isEqual(billedOn as Date, minDate) ||
      isAfter(billedOn as Date, minDate)
    );

  const isValid = !billedOn || isOnOrAfterPaymentDueDate;

  return isValid || this.createError({
    path: `billedOn`,
    message: `Must be after ${formatDate(minDate)}`
  });
};

export default Yup.object().shape({
  taxLevySeason: Yup.string()
    .required(messages.REQUIRED)
    .nullable(),
  year: Yup.number()
    .required(messages.REQUIRED)
    .nullable(),
  taxableValue: Yup.number()
    .min(0, messages.MUST_BE_GREATER_THAN_0)
    .max(999999999999, messages.maxValue(999999999999))
    .required(messages.REQUIRED)
    .typeError(messages.MUST_BE_NUMBER)
    .nullable(),
  millageRate: Yup.number()
    .required(messages.REQUIRED)
    .typeError(messages.MUST_BE_NUMBER)
    .nullable(),
  billedOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .required(messages.REQUIRED)
    .nullable(),
  exemptionPenaltyAmount: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable(),
  billAdjustmentAmount: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999, messages.maxValue(999999999999))
    .nullable(),
  deniedBy: Yup.string()
    .nullable()
    .required(messages.REQUIRED),
  status: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
}).test(validateBilledOn);

