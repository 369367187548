import {useMemo} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDateInput} from '@reasoncorp/kyber-js';

import {bonaFideLetterSchema} from '../../schema/bonaFide';
import {formatDate} from '../../util';

type Props = {
  isOpen: boolean
  onSubmit: (letterSentOn: Date | string) => void
  onCancel: () => void
}

const BonaFideGenerateVoucherLettersModal = ({
                                               isOpen,
                                               onCancel,
                                               onSubmit
                                             }: Props) => {
  const initialValues = useMemo(() => {
    return {
      letterSentOn: formatDate(new Date(), 'yyyy-MM-dd') || ''
    };
  }, []);

  return <Modal isOpen={isOpen}
                onClose={onCancel}
                toggle={() => onCancel()}
                autoFocus={false}
                returnFocusAfterClose={true}
                size="md">
    <ModalHeader toggle={onCancel}
                 tag="h2"
                 className="h5">
      Generate Voucher Letters
    </ModalHeader>
    <Formik initialValues={initialValues}
            validateOnMount
            enableReinitialize
            onSubmit={(values) => onSubmit(values.letterSentOn)}
            validationSchema={bonaFideLetterSchema}>
      {(formikProps) => (<>
        <ModalBody>
          <Row>
            <Col>
              <FormikDateInput name="letterSentOn"
                               aria-required
                               formGroupClass="mb-0"
                               labelText="Select letter date for voucher letter"/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success"
                  className="mr-2"
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  onClick={formikProps.submitForm}>
            Generate
          </Button>
          <Button color="secondary"
                  onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </>)}
    </Formik>
  </Modal>;
};

export default BonaFideGenerateVoucherLettersModal;