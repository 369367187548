import {Button, Table} from 'reactstrap';

import {useUserContext} from '@reasoncorp/kyber-js';

import {BonaFideDenialYear} from '../../types/bonaFide';
import {formatDate, formatDecimal, formatMoney} from '../../util';

type Props = {
  denialYear: BonaFideDenialYear
  onEdit: (denialYear: BonaFideDenialYear) => void
  onDelete: (denialYear: BonaFideDenialYear) => void
}

const BonaFideDenialYearCard = ({
                                  denialYear,
                                  onEdit,
                                  onDelete
                                }: Props) => {
  const {permissions} = useUserContext();

  return <div className="BonaFideDenialYearCard p-4">
    <Table bordered responsive>
      <thead>
        <tr>
          <th className="align-self-center" colSpan={2}>
            {denialYear.year}
          </th>
          <th colSpan={5}>
            {permissions.hasWriteAccess && <div className="justify-content-end d-flex">
              <Button color="primary"
                      className="mr-2"
                      onClick={() => onEdit(denialYear)}
                      size="sm">
                Edit
              </Button>
              <Button color="danger"
                      onClick={() => onDelete(denialYear)}
                      size="sm">
                Delete
              </Button>
            </div>}
          </th>
        </tr>
        <tr>
          <th className="text-center">Denied By</th>
          <th className="text-center">Tax Levy</th>
          <th className="text-center">Status</th>
          <th className="text-center">Billing Date: Request Received Date</th>
          <th colSpan={3}/>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center align-middle">
            {denialYear.deniedByDisplayValue}
          </td>
          <td className="text-center align-middle">
            {denialYear.taxLevySeasonDisplayValue}
          </td>
          <td className="text-center align-middle">
            {denialYear.statusDisplayValue}
          </td>
          <td className="text-center align-middle">
            {formatDate(denialYear.billedOn)}
          </td>
          <td colSpan={3}/>
        </tr>
        <tr>
          <th className="text-center">Millage Rate</th>
          <th className="text-center">Taxable Value</th>
          <th className="text-center">Substantially Similar Exemption Penalty</th>
          <th className="text-center">Bill Adjustment</th>
          <th className="text-center">Tax Due</th>
          <th className="text-center">Interest Due</th>
          <th className="text-center">Total Due</th>
        </tr>
        <tr>
          <td className="text-center align-middle">
            {formatDecimal(denialYear.millageRate)}
          </td>
          <td className="text-center align-middle">
            {formatMoney(denialYear.taxableValue)}
          </td>
          <td className="text-center align-middle">
            {formatMoney(denialYear.exemptionPenaltyAmount)}
          </td>
          <td className="text-center align-middle">
            {formatMoney(denialYear.billAdjustmentAmount)}
          </td>
          <td className="text-center align-middle">
            {formatMoney(denialYear.taxDue)}
          </td>
          <td className="text-center align-middle">
            {formatMoney(denialYear.interestDue)}
          </td>
          <td className="text-center align-middle text-danger">
            {formatMoney(denialYear.totalDue)}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>;
};

export default BonaFideDenialYearCard;