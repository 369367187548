import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';

import {
  CustomTable,
  FormikDateInput,
  FormikNumberInput,
  FormikSelect,
  ProgressIndicator,
  SelectTableCell,
  SelectTableCellData,
  useAlerts
} from '@reasoncorp/kyber-js';

import {DenialNavBar} from '../../components/denials';
import {denialLetterSchema} from '../../schema/denial';
import {CollectionContext} from '../../contexts';
import {DenialSearchResponse} from '../../types/denial';
import {DenialSearchRequest} from '../../types/request/denial';
import {denialApi, denialLetterApi} from '../../api';
import *  as messages from '../../messages';
import {formatDate, getAvailableDenialYears} from '../../util';

type Denial = DenialSearchResponse & {
  selected: boolean
};

const DenialLetters = () => {
  const {collections} = useContext(CollectionContext);
  const [denials, setDenials] = useState<Denial[]>([]);
  const [denialSearchRequest, setDenialSearchRequest] = useState<DenialSearchRequest>({
    year: undefined,
    countyId: undefined,
    majorReason: '',
    hasLetter: false
  });
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loadingState, setLoadingState] = useState({
    loading: false,
    loadError: false,
    processing: false
  });

  const selectedDenialIds = useMemo(() => {
    return denials.filter(denial => denial.selected).map(denial => denial.id);
  }, [
    denials
  ]);

  const handleColumnSelect = useCallback((data: SelectTableCellData) => {
    setDenials(denials.map(denial =>
      data.itemIds.includes(denial.id) ? ({...denial, selected: !data.prevSelection}) : denial));
  }, [
    denials
  ]);

  const handleCellSelect = useCallback((data: SelectTableCellData) => {
    setDenials(
      denials.map(denial => denial.id === data.itemId ? {...denial, selected: !data.prevSelection} : denial)
    );
  }, [
    denials
  ]);

  useEffect(() => {
    const loadDenials = async () => {
      try {
        setLoadingState({loading: true, loadError: false, processing: false});
        const denialSearchResponses = await denialApi.search(denialSearchRequest);
        const denials = denialSearchResponses.map(denialSearchResponses => ({
          ...denialSearchResponses,
          selected: false
        }));
        setDenials(denials);
        setLoadingState({loading: false, loadError: false, processing: false});
        setIsInitialLoad(false);
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadDenials();
  }, [
    denialSearchRequest,
    showErrorAlert
  ]);

  const handleCreate = useCallback(async (letterSentOn?: Date | string) => {
    if (letterSentOn) {
      try {
        setLoadingState({loading: false, loadError: false, processing: true});
        await denialLetterApi.createBatch({
          denialIds: selectedDenialIds,
          letterSentOn: letterSentOn as Date
        });
        const denials = await denialApi.search(denialSearchRequest);
        setDenials(denials as Denial[]);
        showSuccessAlert(messages.LETTER_BATCH_CREATE_SUCCESSFUL);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.LETTER_BATCH_CREATE_FAILURE);
        setLoadingState({loading: false, loadError: false, processing: false});
      }
    }
  }, [
    showErrorAlert,
    showSuccessAlert,
    denialSearchRequest,
    selectedDenialIds
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', onChange: handleColumnSelect},
      {title: 'Year', className: 'text-center'},
      {title: 'Parcel ID', className: 'text-center'},
      {title: 'Owner Names', className: 'text-center'},
      {title: 'County', className: 'text-center'},
      {title: 'Major Reason', className: 'text-center'},
      {title: 'Minor Reason', className: 'text-center'}
    ],

    renderRow: (denial: Denial) => {
      return <tr key={denial.id}>
        <SelectTableCell itemId={denial.id}
                         ariaLabel={`Toggle select for letter for Denial # ${denial.fileNumber}`}
                         selected={denial.selected}
                         onChange={handleCellSelect}/>
        <td className="text-center">
          {denial.year}
        </td>
        <td className="text-center">
          {denial.parcel.parcelNumber}
        </td>
        <td className="text-center">
          {denial.owner.ownerNames}
        </td>
        <td className="text-center">
          {denial.parcel.county.displayName}
        </td>
        <td className="text-center">
          {denial.majorReason}
        </td>
        <td className="text-center">
          {denial.minorReason}
        </td>
      </tr>;
    },
    items: denials,
    noResultsMessage: messages.NO_DENIAL_LETTERS_AVAILABLE
  }), [
    denials,
    handleColumnSelect,
    handleCellSelect
  ]);

  const initialValues = useMemo(() => ({
    ...denialSearchRequest,
    letterSentOn: formatDate(new Date(), 'yyyy-MM-dd'),
    perPage: 100
  }), [
    denialSearchRequest
  ]);

  return <Container fluid>
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <DenialNavBar/>
      </Col>
    </Row>
    {loadingState.loading && isInitialLoad && <ProgressIndicator/>}
    {!loadingState.loadError && !isInitialLoad &&
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={async (values, formikHelpers) => {
                await handleCreate(values.letterSentOn);
                formikHelpers.resetForm();
              }}
              validationSchema={denialLetterSchema}>
        {(formikProps) => (<>
          <Row className="mb-4">
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="6">Generate Denial Letters</Col>
                    <Col className="d-flex justify-content-end" sm="6">Viewing {denials.length} Denials</Col>
                  </Row>
                </CardHeader>
                <CardBody className="pb-0">
                  <Row>
                    <Col sm="1">
                      <FormikSelect name="year"
                                    onChange={(e) => {
                                      setDenialSearchRequest({
                                        ...denialSearchRequest,
                                        year: e.target.value !== '' ? Number(e.target.value) : undefined
                                      });
                                    }}
                                    labelText="Year"
                                    formGroupClass="mb-0">
                        <option value="">Select</option>
                        {getAvailableDenialYears.map(year => <option value={year} key={year}>
                          {year}
                        </option>)}
                      </FormikSelect>
                    </Col>
                    <Col sm="4">
                      <FormikSelect name="countyId"
                                    onChange={(e) => {
                                      setDenialSearchRequest({
                                        ...denialSearchRequest,
                                        countyId: e.target.value !== '' ? Number(e.target.value) : undefined
                                      });
                                    }}
                                    labelText="County"
                                    formGroupClass="mb-0">
                        <option value="">Select</option>
                        {collections?.counties.map(county =>
                          <option value={county.id} key={county.id}>
                            {county.displayName}
                          </option>)
                        }
                      </FormikSelect>
                    </Col>
                    <Col sm="4">
                      <FormikSelect name="majorReason"
                                    onChange={(e) => {
                                      setDenialSearchRequest({
                                        ...denialSearchRequest,
                                        majorReason: e.target.value
                                      });
                                    }}
                                    labelText="Major Reason"
                                    formGroupClass="mb-0">
                        <option value="">Select</option>
                        {collections?.majorReasons.map(majorReason =>
                          <option value={majorReason} key={majorReason}>
                            {majorReason}
                          </option>)
                        }
                      </FormikSelect>
                    </Col>
                    <Col sm="2">
                      <FormikDateInput name="letterSentOn"
                                       formGroupClass="mb-0"
                                       labelText="Letter Date"/>
                    </Col>
                    <Col sm="1">
                      <FormikNumberInput labelText="View Per Page"
                                         disableFloatingLabel
                                         maxLength={3}
                                         name="perPage"/>
                    </Col>
                  </Row>
                </CardBody>
                <Row>
                  <Col>
                    {loadingState.loading && <ProgressIndicator/>}
                    {!loadingState.loading && <CustomTable {...tableProps}
                                                           paginatorConfig={{
                                                             perPage: formikProps.values.perPage ? Number(formikProps.values.perPage) : 10
                                                           }}/>}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button color="success"
                      disabled={loadingState.processing || formikProps.isSubmitting || !formikProps.isValid || selectedDenialIds.length === 0}
                      onClick={formikProps.submitForm}>
                Send Letters
              </Button>
            </Col>
          </Row>
        </>)}
      </Formik>}
  </Container>;
};

export default DenialLetters;